import React from 'react';

const PlayroomIcon = ({size}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size === 'md' ? 32 : size} height={size === 'md' ? 32 : size} fill="none" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.522.022h-.5v23.956h23.956V.022H.522zm10.978 1H6.24V14.63h5.26V1.022zm0 14.608H1.022v7.348h21.956V15.63H11.5zm1-14.608V14.63h10.478V1.022H12.5zm-7.26 0V14.63H1.021V1.022h4.217z"
      clipRule="evenodd"
    />
  </svg>
);

export default PlayroomIcon;
