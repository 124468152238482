import React from 'react';

const FigmaIcon = ({size}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size === 'md' ? 32 : size} height={size === 'md' ? 32 : size} fill="none" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 4.332A4.332 4.332 0 018.332 0h7.665a4.332 4.332 0 012.022 8.165 4.332 4.332 0 11-5.355 6.6v4.896A4.332 4.332 0 116.31 15.83a4.332 4.332 0 010-7.664A4.332 4.332 0 014 4.332zm7.665 3.332H8.322A3.332 3.332 0 018.332 1h3.332v6.664zm-3.343 1H11.664v6.665H8.332a3.332 3.332 0 01-.01-6.665zm4.343 0v.564c.17-.205.358-.393.563-.563h-.563zm3.342-1h-3.342V1h3.332a3.332 3.332 0 01.01 6.665zm-.024 1a3.332 3.332 0 10.024 0h-.024zm-7.65 7.665a3.332 3.332 0 103.332 3.332V16.33H8.332z"
      clipRule="evenodd"
    />
  </svg>
);

export default FigmaIcon;
