import React from 'react';
import styled from 'styled-components';
import {Link as GatsbyLink} from 'gatsby';

import Card from '@nib/card';
import {breakpoint} from '@nib/layout';
import UtilityButton from '@nib/utility-button';

// A regular hidden component will not grow to fill the container
const FlexHiddenBelowXl = styled.div`
  display: none;

  ${breakpoint('xl')`
    display: flex;
  `}
`;

const FlexHiddenAboveXl = styled.div`
  display: flex;
  width: 100%;

  ${breakpoint('xl')`
    display: none;
  `}
`;

const HomePageCard = ({icon, title, to, children}) => (
  <>
    <FlexHiddenAboveXl>
      <UtilityButton icon={icon} href={to}>
        <UtilityButton.Title>{title}</UtilityButton.Title>
      </UtilityButton>
    </FlexHiddenAboveXl>

    <FlexHiddenBelowXl>
      <Card icon={icon} padding={6} component={GatsbyLink} to={to}>
        <Card.Content title={title}>{children}</Card.Content>
      </Card>
    </FlexHiddenBelowXl>
  </>
);

export default HomePageCard;
