import React from 'react';

const LayoutIcon = ({size}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size === 'md' ? 32 : size} height={size === 'md' ? 32 : size} fill="none" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.36 1.006a.302.302 0 00-.36.296v20.524a1.104 1.104 0 001.104 1.104H22.63a.303.303 0 00.213-.515L1.516 1.088a.302.302 0 00-.155-.082zM2.223.38A1.302 1.302 0 000 1.303v20.523a2.104 2.104 0 002.104 2.104H22.63a1.304 1.304 0 001.276-1.555 1.302 1.302 0 00-.355-.667L2.222.381z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.122 10.936a.5.5 0 01.545.108l7.219 7.22a.5.5 0 01-.353.854h-7.22a.5.5 0 01-.5-.5v-7.22a.5.5 0 01.309-.462zm.691 1.67v5.511h5.512l-5.512-5.512zM6.469 4.627a.5.5 0 010 .707L4.864 6.94a.5.5 0 01-.707-.708l1.605-1.604a.5.5 0 01.707 0zM10.48 8.638a.5.5 0 010 .707L8.874 10.95a.5.5 0 11-.707-.707l1.604-1.604a.5.5 0 01.707 0zM14.49 12.649a.5.5 0 010 .707l-1.604 1.604a.5.5 0 11-.707-.707l1.604-1.604a.5.5 0 01.707 0zM18.501 16.66a.5.5 0 010 .707l-1.604 1.604a.5.5 0 11-.707-.707l1.604-1.604a.5.5 0 01.707 0z"
      clipRule="evenodd"
    />
  </svg>
);

export default LayoutIcon;
