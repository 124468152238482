import React from 'react';
import styled from 'styled-components';
import {Link as GatsbyLink} from 'gatsby';
import Helmet from 'react-helmet';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import {Box, Columns, Column, Section, Stack, breakpoint, py} from '@nib/layout';
import {Footer, HeroPanel, Layout, SEO} from '@nib/gatsby-theme-mesh-docs';
import {
  AssessmentGraphicIcon,
  BookmarkGraphicIcon,
  DentalGraphicIcon,
  EnablingAccessibilityGraphicIcon,
  GlobeGraphicIcon,
  LifeInsuranceGraphicIcon,
  RewardsGraphicIcon,
  VipRewardsGraphicIcon
} from '@nib/icons';

import Header from '../components/header/Header';
import FigmaIcon from '../components/icons/FigmaIcon';
import LayoutIcon from '../components/icons/LayoutIcon';
import PlayroomIcon from '../components/icons/PlayroomIcon';
import StorybookIcon from '../components/icons/StorybookIcon';
import HomePageCard from '../components/cards/HomePageCard';
import Banner from '../components/banner';

const CardSection = styled(Section)`
  ${py(6)};

  ${breakpoint('xl')`
    ${py(7)};
  `}
`;

const ContentColumn = ({title, children}) => (
  <Box marginBottom={{xs: 5, xl: 0}}>
    <Stack space={5}>
      <Heading size={3} component="h2" color="trueGreen">
        {title}
      </Heading>

      {children}
    </Stack>
  </Box>
);

const IndexPage = () => (
  <Layout bannerContent={<Banner />}>
    <SEO />
    <Header />

    <Helmet>
      <meta name="ahrefs-site-verification" content="943ee72ed7998b222733cc30ae110547fdf59126d887904e998728390d7c01f7" />
    </Helmet>

    <HeroPanel />

    <CardSection background="warmWhite">
      <Columns space={{xs: 3, xl: 5}} collapseBelow="xl" flex>
        <Column>
          <ContentColumn title="Getting Started">
            <Copy>nib’s Mesh Design System enables teams to create cohesive, high-quality products and experiences faster for our existing and prospective members, providers and partners.</Copy>
            <Link as={GatsbyLink} to="/getting-started/overview/">
              Get started with Mesh
            </Link>
          </ContentColumn>
        </Column>

        <Column flex>
          <HomePageCard title="Design principles" icon={AssessmentGraphicIcon} to="/getting-started/design-principles/">
            <Copy>
              These principles underlie the way we ‘do design’ at nib. They encourage us to continue striving to produce elegant products and solutions that best balance the needs of our end users
              with our business goals.{' '}
            </Copy>
          </HomePageCard>
        </Column>

        <Column flex>
          <HomePageCard title="Accessibility" icon={EnablingAccessibilityGraphicIcon} to="/getting-started/accessibility/accessibility-statement/">
            <Copy>
              Accessibility enables people with physical and cognitive disabilities, situational disabilities and technology limitations to perceive and interact with the digital experiences we
              create.{' '}
            </Copy>
          </HomePageCard>
        </Column>

        <Column flex>
          <HomePageCard title="Foundations" icon={GlobeGraphicIcon} to="/foundations/overview/">
            <Copy>Our Foundations library covers nib’s use of color, typography, imagery, iconography, layout and spacing, to ensure consistency across our digital products and services.</Copy>
          </HomePageCard>
        </Column>
      </Columns>
    </CardSection>

    <CardSection background="sunsetPink">
      <Columns space={{xs: 3, xl: 5}} collapseBelow="xl" flex>
        <Column>
          <ContentColumn title="Design">
            <Copy>
              Designing digital products and services with Mesh can help to make your work easier, by utilising established design patterns and leveraging tooling such as Figma component libraries and
              Playroom.
            </Copy>
            <Link as={GatsbyLink} to="/getting-started/design/">
              Learn more about designing with Mesh
            </Link>
          </ContentColumn>
        </Column>

        <Column flex>
          <HomePageCard title="Figma libraries" icon={FigmaIcon} to="/getting-started/design/#figma">
            <Copy>Our Figma libraries provide reusable components and styles to help you craft great products and design quickly for fast prototyping in high fidelity.</Copy>
          </HomePageCard>
        </Column>

        <Column flex>
          <HomePageCard title="Layout" icon={LayoutIcon} to="/foundations/layout/">
            <Copy>Mesh provides a set of utility layout components which manage structure and spacing between elements.</Copy>
          </HomePageCard>
        </Column>

        <Column flex>
          <HomePageCard title="Playroom" icon={PlayroomIcon} to="/resources/playroom/">
            <Copy>Playroom allows you to quickly experiment with real Mesh components, real interactivity and real limitations of both the system and the web platform. </Copy>
          </HomePageCard>
        </Column>
      </Columns>
    </CardSection>

    <CardSection background="sageGreen">
      <Columns space={{xs: 3, xl: 5}} collapseBelow="xl" flex>
        <Column>
          <ContentColumn title="Develop">
            <Copy>
              Develop better experiences quickly and efficiently with Mesh, leveraging our React Component library, site starter templates and tooling like Storybook to effectively implement your
              designs to code.
            </Copy>
            <Link as={GatsbyLink} to="/getting-started/develop/">
              Learn more about developing with Mesh
            </Link>
          </ContentColumn>
        </Column>

        <Column flex>
          <HomePageCard title="Components" icon={RewardsGraphicIcon} to="/components/overview/">
            <Copy>Mesh’s library of React Components form the building-blocks for all nib experiences, from forms and interactive elements to commonly used content and messaging patterns.</Copy>
          </HomePageCard>
        </Column>

        <Column flex>
          <HomePageCard title="Storybook" icon={StorybookIcon} to="/resources/storybook/">
            <Copy>Storybook allows you to quickly experiment with real Mesh components, real interactivity and real limitations of both the system and the web platform. </Copy>
          </HomePageCard>
        </Column>

        <Column flex>
          <HomePageCard title="Static site starter" icon={BookmarkGraphicIcon} to="/resources/static-site-starter/">
            <Copy>
              Our static site starter has been created as a template repository for creating your own repo, utilising Gatsby, a React-based open source framework for creating websites and apps.
            </Copy>
          </HomePageCard>
        </Column>
      </Columns>
    </CardSection>

    <CardSection background="lightest">
      <Columns space={{xs: 3, xl: 5}} collapseBelow="xl" flex>
        <Column>
          <ContentColumn title="Resources">
            <Copy>Quickly navigate to commonly accessed resources for nib’s internal teams and external partners.</Copy>
          </ContentColumn>
        </Column>

        <Column flex>
          <HomePageCard title="Brand" icon={LifeInsuranceGraphicIcon} to="/brand/overview/">
            <Copy>
              Our library of brand assets includes logos for nib and its partners, email templates and brand imagery guidelines to ensure consistent tone-of-voice through our use of visual assets.
            </Copy>
          </HomePageCard>
        </Column>

        <Column flex>
          <HomePageCard title="Iconography" icon={DentalGraphicIcon} to="/foundations/iconography/usage/">
            <Copy>nib’s icon library includes system and graphic icon assets, designed for visual consistency and communication across sizes and contexts.</Copy>
          </HomePageCard>
        </Column>

        <Column flex>
          <HomePageCard title="CSS Framework" icon={VipRewardsGraphicIcon} to="/resources/css-framework/">
            <Copy>
              Designed as a standalone library independent of any javascript frameworks, our CSS Framework provides component-agnostic utility classes that allow for design flexibility while
              maintaining alignment with nib’s digital assets.
            </Copy>
          </HomePageCard>
        </Column>
      </Columns>
    </CardSection>

    <Footer />
  </Layout>
);

export default IndexPage;
